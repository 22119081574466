import React from "react"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import briefingCover from "../images/Cover from GMedC_1.jpg"

export default function IndexPage() {
  return (
    <Layout>
      <SEO title="Home" />

      <h2>Global Medicines Council</h2>
      <p>
        The Global Medicines Council is a group of experts in the field of
        pharmaceuticals committed to ensuring the production and distribution of
        safe and affordable medicines, and in assuring universal access to them.
        The group is committed to promoting innovation, including through newly
        developed alternative models. The Global Medicines Council will
        undertake research and prepare reports to assist in the development and
        implementation of pharmaceutical sector policy.{" "}
      </p>
      <div style={{ maxWidth: `100px`, margin: `1.45rem 0` }}>
        <Image />
      </div>
      <br />
      <hr />
      <br />
      <h3>Briefing Paper No 1 Released</h3>
      <p>
        August 16, 2017 - The Global Medicines Council today publishes its{" "}
        <a href={"/documents/GMedC-Briefing_Paper_No_1-Final.pdf"}>
          {"Briefing Paper No 1"}
        </a>{" "}
        that encapsulates presentations, discussions and recommendations of its
        Inaugural Meeting held in Tallahassee, Florida on April 14-15, 2017. The
        ideas behind the Global Medicines Council were initially discussed in
        Geneva, Switzerland in 2011, and further elaborated in meetings in
        Tremblant, Canada. The formal establishment of the Global Medicines
        Council as a non-profit organization followed the Florida meeting. The
        presentations in Briefing Paper No 1 cover a range of subject matter
        relating to achieving the goal of universal access to medicines, as well
        as encouraging sustainable patient-centric innovation.
      </p>
      <div>
        <a href={"/documents/GMedC-Briefing_Paper_No_1-Final.pdf"}>
          <img alt="" src={briefingCover} />
        </a>
      </div>
    </Layout>
  )
}
